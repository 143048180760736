
import Paging from '@/components/actions/Paging.vue'
import MemberInactiveCard from '@/components/collections/member/MemberInactiveCard.vue'
import BusinessBranchFilter from 'tradingmate-modules/src/filters/BusinessBranchFilter'
import { BusinessBranchStatus } from 'tradingmate-modules/src/models/api/businesses'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Vue, Watch } from 'vue-property-decorator'
import MemberCard from '../components/collections/member/MemberCard.vue'
import SearchBar from '../components/collections/member/SearchBar.vue'
import BusinessBranchSearchResponse
  from '../../../tradingmate-modules/src/models/api/businesses/BusinessBranchSearchResponse'
import { ZoneModel } from '../../../tradingmate-modules/src/models/api/admin'
import BusinessBranchOrCategorySearchResult
  from '../../../tradingmate-modules/src/models/api/search/BusinessBranchOrCategorySearchResult'

@Component({
  components: {
    SearchBar,
    MemberCard,
    Paging,
    MemberInactiveCard
  }
})

export default class MemberSearch extends Vue {
  searchResponse: BusinessBranchSearchResponse | null = null

  businessBranchFilter: BusinessBranchFilter = new BusinessBranchFilter()
  // zoneIds: string[] | null = null
  // categoryIds: string[] | null = null
  private loading = false;
  private hasInititalSearch = false

  private branchActive = BusinessBranchStatus.Active
  private branchInactive = BusinessBranchStatus.Inactive

  mounted (): void {
    this.search()
    console.log('filter')
    console.log(this.businessBranchFilter.CategoryIds)
  }

  getZoneResponse (): ZoneModel | null {
    return this.searchResponse?.Zone ?? null
  }

  getCategoryResponse (): BusinessBranchOrCategorySearchResult | null {
    return this.searchResponse?.SearchResult ?? null
  }

  getCategorySearchResponse (): string | null {
    return this.searchResponse?.BusinessSearch ?? null
  }

  getZoneSearchResponse (): string | null {
    return this.searchResponse?.ZoneSearch ?? null
  }

  @Watch('$route')
  search (): void {
    this.updateFilter()
    this.searchBranches()
  }

  updateFilter (): void {
    this.businessBranchFilter.Search = this.$route.query.s?.toString()
    this.businessBranchFilter.ZoneSearch = this.$route.query.zs?.toString()
    this.businessBranchFilter.CategoryIds = this.$route.query.categories?.toString().split(',')
    this.businessBranchFilter.ZoneIds = this.$route.query.zones?.toString().split(',')
    this.businessBranchFilter.Page = this.$route.query.page ? Number.parseInt(this.$route.query.page.toString()) : 1
  }

  searchBranches (): void {
    this.loading = true
    this.hasInititalSearch = true

    console.log('searchBranches')
    console.log(this.businessBranchFilter)

    Services.API.Businesses.SearchBranchesForPublic(this.businessBranchFilter)
      .then((returnData) => {
        console.log('returned: ')
        console.log(returnData)
        this.searchResponse = returnData
      }).catch(() => {
        console.error('query failed')
      }).finally(() => { this.loading = false })
  }

  navToPage (nextPage: number): void {
    console.log('paging to: ' + nextPage)
    this.$router.push({ path: this.$route.fullPath, query: { page: nextPage.toString() } })
    this.search()
  }
}

